.bg_footer{
    background-color: var(--main-gray);
    width: 100vw;
}

.footer_logo{
    width: 50%;
}

a:hover{
    text-decoration: none;
}

@media (min-width:320px) and (max-width:500px){
    .footer_logo{
        width: 15vw;
    }
}