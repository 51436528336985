@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300;500;700&display=swap');

:root {
  --main-blue: #001689;
  --main-yellow: #f49719;
  --main-pink: #e0467b;
  --dark-gray: #1d1d1b;
  --main-gray: #e4e1e6;
  
}

.App {
  text-align: left;
  font-family: 'Libre Franklin', sans-serif;
}


html {
  scroll-behavior: smooth;
}

main {
  width: 100vw;
  
}

.multiline {
  white-space: pre-line;
}

.text-dark {
  color: black;
}

.text-pink{
  color:var(--main-pink)
}

.text-yellow{
  color: var(--main-yellow)
}

.text-gray{
  color: var(--main-gray)
}

.abs_cont {
  position: relative;
}

.min_100 {
  min-height: 100vh;
}

.color_yellow {
  color: var(--main-yellow);
}

.hr_contrast {
  border-bottom: 1px solid #f1b037;
}
.hr_light {
  border-bottom: 1px solid #fff;
}

.hr_blue {
  border-bottom: 1px solid #20244c;
  position: relative;
}

.hr_blue .hr_fat {
  position: absolute;
  left: 0;
  top: -0.5rem;
  width: 3rem;
  height: 1rem;
  background: #20244c;
}

h6{
  font-size: 5rem;
}

a{
  color:black;
}

a:active{
  color: var(--main-pink)
}

.btn.btn-primary {
  background: var(--main-pink);
  color: white;
  border-color: none !important;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-secondary:active {
  background: var(--main-pink) !important;
  color: var(--main-gray) !important;
  border-color: none !important;
}


/*Entering*/
.fadeTranslate-enter {
  opacity: 0;
  transform: translate(-3vw, 0);
  position: fixed;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 500ms ease-in;
}

.fadeTranslate-exit {
  opacity: 1;
  position: fixed;
  transform: translate(0, 0);
}

.fadeTranslate-exit.fadeTranslate-exit-active {
  opacity: 0;
  transform: translate(3vw, 0);
  transition: all 400ms ease-in;
}

p{
  font-size: 11pt;
}


@media (min-width:320px) and (max-width:500px){
  h6{
    font-size: 3.5rem;
  }
  h5{
    font-size: 1rem;
  }
  h4{
    font-size: 1.2rem;
  }
  h3{
    font-size:1.5rem;
  }
}