/* dialog */

.gallery-dialog {
  min-width: 320px;
  min-height: 400px;

  width: 80vw;
}

.gallery-dialog::backdrop {
  background: rgba(0, 0, 0, 0.65);
}

.gallery-dialog .dialog-content {
  min-height: 100%;
}

.gallery-dialog .dialog-content img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

@media (max-width: 478px) {
  .gallery-dialog {
    width: 100vw;
    height: 100vh;
  }
  .gallery-dialog .dialog-content img {
    object-fit: contain;
  }
}

.bg_home {
  background-image: url("../assets/imgs/landing2023/cover_home.jpg");
  background-size: cover;
  position: relative;
  width: 100%;
  z-index: 1;
  height: 70vh;
}
.bg-fooddesign {
  background-image: url("../assets/imgs/bgfood.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-open{
  background-image: url("../assets/imgs/landing2022/cover_taller_ladrillos.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}
.bg-designnight{
  background-image: url("../assets/imgs/landing2022/cover_design_night.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-virtual-showcase {
  background-image: url("../assets/imgs/landing2022/cover_expos.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-territory {
  background-image: url("../assets/imgs/landing2022/cover_territorio.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-arttoy {
  background-image: url("../assets/imgs/landing2022/cover_arttoy.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-sanfran {
  background-image: url("../assets/imgs/bgsanfran.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-us {
  background-image: url("../assets/imgs/bgus.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-links {
  background-image: url("../assets/imgs/landing/enlacebg.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-ciudad {
  background-image: url("../assets/imgs/landing2022/cover_pertenencia.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}
.bg-loteria {
  background-image: url("../assets/imgs/landing2022/cover_loteria.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-fachadas {
  background-image: url("../assets/imgs/landing2022/cover_fachadas.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-aswer {
  background-image: url("../assets/imgs/landing2023/exposiciones/raices/03.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-violencia {
  background-image: url("../assets/imgs/landing2023/cartel_violencia.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-interiorismo {
  background-image: url("../assets/imgs/landing2022/cover_interiorismo.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-regiones {
  background-image: url("../assets/imgs/landing/regionesbg.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-firstUs {
  background-image: url("../assets/imgs/landing2022/nosotros/cover_primera.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}
.bg-secondUs {
  background-image: url("../assets/imgs/landing2022/nosotros/cover_segunda.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}
.bg-thirdUs {
  background-image: url("../assets/imgs/landing2022/nosotros/cover_tercera.jpg");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-meridabarroquito {
  background-image: url("../assets/imgs/barroquito-merida.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-trans {
  background-image: url("../assets/imgs/bg-tras.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-letrados {
  background-image: url("../assets/imgs/landing2022/cover_objetos_letrados.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-entre {
  background-image: url("../assets/imgs/expo/expo-entretejidas.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-kei {
  background-image: url("../assets/imgs/talleres/taller-d.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}

.bg-brisa {
  background-image: url("../assets/imgs/talleres/taller-macrame.png");
  background-size: cover;
  position: relative;
  z-index: 1;
  height: 70vh;
  background-position-y: center;
}
.logo_home {
  width: 16vw;
  margin-top: -6vw;
}

.img_allies {
  width: 20vw;
  padding: 0rem 0rem;
}

.worksimg {
  width: 35vw;
  height: 15vw;
}

.img_barroquito {
  width: 65vw;
}

.cstm_border {
  border-style: solid;
  border-width: 2px 2px;
  padding: 2vh 2vw;
}

@media (min-width: 320px) and (max-width: 500px) {
  .begin_dwp {
    width: 30vw;
  }

  .img_barroquito {
    width: 80vw;
  }
  .custom-landing-img {
    min-width: 45vw;
    width: 45vw;
  }

  .custom-food-img {
    min-width: 40vw;
    width: 80vw;
  }
  .bg_home {
    background-image: url("../assets/imgs/cover-principal.png");
    background-size: cover;
    position: relative;
    background-position: center;
    z-index: 1;
    width: 100vw;
    height: 22vh;
  }

  .bg-links {
    background-image: url("../assets/imgs/landing/enlacebg.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-ciudad {
    background-image: url("../assets/imgs/landing2022/cover_pertenencia.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }
  .bg-fooddesign {
    background-image: url("../assets/imgs/bgfood.jpg");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-territory {
    background-image: url("../assets/imgs/landing2022/cover_territorio.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-sanfran {
    background-image: url("../assets/imgs/bgsanfran.jpg");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-loteria {
    background-image: url("../assets/imgs/landing2022/cover_loteria.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }
  .bg-meridabarroquito {
    background-image: url("../assets/imgs/barroquito-merida.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-regiones {
    background-image: url("../assets/imgs/landing/regionesbg.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-us {
    background-image: url("../assets/imgs/bgus.jpg");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-trans {
    background-image: url("../assets/imgs/bg-tras.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-letrados {
    background-image: url("../assets/imgs/landing2022/cover_objetos_letrados.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-entre {
    background-image: url("../assets/imgs/expo/expo-entretejidas.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-kei {
    background-image: url("../assets/imgs/talleres/taller-d.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }

  .bg-brisa {
    background-image: url("../assets/imgs/talleres/taller-macrame.png");
    background-size: cover;
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 30vh;
  }
}
