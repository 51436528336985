.barroquito_img{
    width: 20vw;
}

.custom_long{
    width: 35vw;
}

@media (min-width:320px) and (max-width:500px){
    .barroquito_img{
        width: 30vw;
    }

    .custom_long{
        width: 25vw;
    }


}