.logo_nav{
    width: 10rem;
}

.dropdown{ 
    color: black;
}

a:hover {
    color: var(--main-pink);
}

.custom_drop{
    padding: 2.3vh 2vw;
}

.nav_color:hover{
    color: var(--main-pink)
}


@media (min-width:1300px) and (max-width:1800px){
    h4{
        font-size: 2vw;
    }
}

@media (min-width:1000px) and (max-width:1299px){


}

@media (min-width:320px) and (max-width:500px){
    .logo_nav{
        width: 8vw;
    }
}
